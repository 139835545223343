<template>
  <!-- scan START -->
  <div class="scan">

    <h1 v-html="$t('scan.scanproducts')"></h1>

    <!-- scan-options START -->
    <div class="scan-options">

      <!-- scan-options:option START -->
      <div
        :class="[
          'scan-options-option',
          { 'deactivated' : !activate }
        ]"
      >

        <router-link
          :to="'/cart'"
          class="f-link"
        ></router-link>

        <div class="icon">
          <img width="64" :src="iconSmartphone" />
        </div>

        <div
          class="headline"
          v-html="$t('scan.withthesmartphone')"
        ></div>
      </div>
      <!-- scan-options:option END -->

      <!-- scan-options:option START -->
      <div class="scan-options-option">
        <router-link
          :to="'/scan/terminal'"
          class="f-link"
        ></router-link>

        <div class="icon">
          <img width="64" :src="iconTerminal" />
        </div>

        <div
          class="headline"
          v-html="$t('scan.withtheterminal')"
        ></div>
      </div>
      <!-- scan-options:option END -->

    </div>
    <!-- scan-options END -->

  </div>
  <!-- scan END -->
</template>

<script>
import ICONS from '../utils/icons';

const {
  iSmartphone,
  iTerminal,
} = ICONS;

export default {
  name: 'scan',
  data() {
    return {
      activate: false,
      boxFetched: null,
      iconSmartphone: localStorage && localStorage.getItem('theme') === 'light' ? iSmartphone.defaultUrl : iSmartphone.greenUrl,
      iconTerminal: localStorage && localStorage.getItem('theme') === 'light' ? iTerminal.defaultUrl : iTerminal.greenUrl,
    };
  },
  created() {
    this.getBox();
  },
  methods: {
    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    async getBox() {
      const t = this;

      await this.FETCH_SETTINGS().then((settings) => {
        if (t.boxFetched === null && settings) {
          const timeoutHasEnteredBox = settings.app.timeouts.hasEnteredBox;
          t.boxFetched = setInterval((function getBoxInit() {
            // select user/box
            t.$http.get(`${process.env.VUE_APP_BASE_URL}user/hasEnteredBox`, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data && response.data.hasEnteredBox === true) {
                  localStorage.setItem('locationID', response.data.box.id);
                  t.$store.commit('SET_LOCATION_ID', response.data.box.id);
                  sessionStorage.setItem('activateNav', true);
                  t.activate = true;
                } else {
                  sessionStorage.setItem('activateNav', false);
                  t.activate = false;
                }
              })
              .catch((error) => {
                if (!error.response) {
                  clearInterval(t.boxFetched);
                  t.activate = false;
                  // network error
                  t.errorStatus = 'Error: Network Error';
                } else {
                  t.errorStatus = error.response.data;
                }
              });
            return getBoxInit;
          }()), timeoutHasEnteredBox);
        }
      });
    },
  },
  mounted() {
    // close $notify from Payback Screen
    this.$notify.closeAll();
  },
  beforeDestroy() {
    clearInterval(this.boxFetched);
  },
};
</script>
